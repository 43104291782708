
  /*@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";*/
  /*@import "@/scss/_bulmaOverridden";*/

  div.testim-panel {
    min-height: 20em;
    padding-bottom: 1em;
  }

  div.testim-panel-text {
    padding-left: 2em;
    padding-right: 2em;
    margin-bottom: 0;
  }


